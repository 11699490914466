import { EnumToArray } from '../../../utils/enumToArray';

enum Orientation {
  Right = 'right',
  Left = 'left',
  Bottom = 'bottom'
};

export const PTZDrawerConfigList = {
  Orientation: EnumToArray([Orientation]),
};

export const PTZDrawerConfig = {
  Orientation,
};
