import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Appearance {
  Light = 'light',
  Dark = 'dark',
  Color = 'color',
}

enum IconAlign {
  Left = 'left',
  Right = 'right',
}

enum Kind {
  Primary = 'primary',
  Secondary = 'secondary',
  Lighter = 'lighter',
  Ghost = 'ghost',
}

enum Size {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
  XSmall = 'xs',
  LargeFullWidth = 'lg-full-width',
  MediumFullWidth = 'md-full-width',
  SmallFullWidth = 'sm-full-width',
  XSmallFullWidth = 'xs-full-width',
}

enum Type {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export const PTZButtonConfigList = {
  Appearance: EnumToArray([Appearance]),
  Brand: PTZBrandConfigList.Name,
  IconAlign: EnumToArray([IconAlign]),
  Kind: EnumToArray([Kind]),
  Size: EnumToArray([Size]),
  Type: EnumToArray([Type]),
};

export const PTZButtonConfig = {
  Appearance,
  Brand: PTZBrandConfig.Name,
  IconAlign,
  Kind,
  Size,
  Type,
};
